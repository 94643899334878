<template>
<div>
  <div class="date-list-item" v-for="date in tileData" :key="date.joining_date">
    <p class="text-lg text-medium p-6 ">{{ date.text }}</p>
  </div>
</div>
</template>

<script>
export default {
  name: "AccordionTile",
  props: ["tileData"],
};
</script>

<style scoped></style>
