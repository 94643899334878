<template>
  <div>
    <div class="text relative rounded-lg px-4 py-2 transition duration-300 my-3 m-4 shadow" v-for="exp in tileData" :key="exp.experience_id">
      <div class="experience-header  font-semibold mb-1">
        <span class="text-xl">{{ exp.name }}</span>
      </div>
      <div class="experience-description">
        <p class="text-gray-600 text-sm font-semibold">
          {{ exp.description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccordionExpTile",
  props: ["tileData"],
};
</script>

<style scoped></style>
