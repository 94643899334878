<template>
  <div>
    <div
      class="cat-detail"
      :class="{
        'rounded-lg': type == 'flows',
      }"
    >
      <div
        class="acc-header flex justify-between place-items-center "
        :class="{
          'cursor-pointer pointer-events-auto': content.total > 0,
          'cursor-default pointer-events-none': content.total == 0,
        }"
        @click="setHeight(content.slug, content.total)"
      >
        <div
          class="font-semibold text-xl tracking-wide flex justify-between w-full px-4 py-3"
        >
          <p class="w-2/3 flex place-items-center">{{ title }}</p>

          <div class="w-1/3 flex flex-row place-items-center justify-end gap-2">
            <div
              v-if="content.total > 0"
              class="flex flex-row place-items-center justify-end gap-2"
            >
              <div
                class="rounded-full w-8 h-8 bg-green-600 flex justify-center place-items-center"
              >
                <span class="badge text-white font-bold text-md">
                  {{ content.total }}
                </span>
              </div>
              <div class="w-9 h-9">
                <svg
                  class="svg-icon"
                  viewBox="0 0 20 20"
                  v-if="!expandContent[content.slug]"
                >
                  <path
                    fill="#3C50E0"
                    d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"
                  ></path>
                </svg>
                <svg class="svg-icon" viewBox="0 0 20 20" v-else>
                  <path
                    fill="#4b5563"
                    d="M13.889,11.611c-0.17,0.17-0.443,0.17-0.612,0l-3.189-3.187l-3.363,3.36c-0.171,0.171-0.441,0.171-0.612,0c-0.172-0.169-0.172-0.443,0-0.611l3.667-3.669c0.17-0.17,0.445-0.172,0.614,0l3.496,3.493C14.058,11.167,14.061,11.443,13.889,11.611 M18.25,10c0,4.558-3.693,8.25-8.25,8.25c-4.557,0-8.25-3.692-8.25-8.25c0-4.557,3.693-8.25,8.25-8.25C14.557,1.75,18.25,5.443,18.25,10 M17.383,10c0-4.07-3.312-7.382-7.383-7.382S2.618,5.93,2.618,10S5.93,17.381,10,17.381S17.383,14.07,17.383,10"
                  ></path>
                </svg>
              </div>
            </div>
            <div
              v-else
              class="flex flex-row place-items-center justify-end gap-2"
            >
              <div
                class="rounded-full w-8 h-8 bg-gray-300 flex justify-center place-items-center"
              >
                <span class="badge text-white font-bold text-md">
                  0
                </span>
              </div>
              <div class="w-9 h-9">
                <svg class="svg-icon" viewBox="0 0 20 20">
                  <path
                    fill="#d1d5db"
                    d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        :ref="content.slug"
        class="date-list transition-all duration-500 ease-in-out overflow-hidden border-t border-gray-300"
        :class="{
          'active-height': expandContent[content.slug],
          'max-h-0': !expandContent[content.slug],
        }"
      >
        <AccordionTile v-if="type == 'flows'" :tileData="content.data" />

        <AccordionExpTile v-else :tileData="content.data" />
      </div>
    </div>
  </div>
</template>

<script>
import AccordionExpTile from "./AccordionExpTile.vue";
import AccordionTile from "./AccordionTile.vue";

export default {
  name: "Accordion",
  components: { AccordionTile, AccordionExpTile },
  data() {
    return {
      expandContent: {
        "candidate-exps": false,
        "first-day-at-work": false,
        "employee-exps": false,
      },
    };
  },
  props: {
    content: {
      required: true,
      type: Object,
    },
    type: {
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    setHeight(slug, count) {
      if (count > 0) {
        this.expandContent[slug] = !this.expandContent[slug];
        let el = this.$refs[slug];
        let height = el.scrollHeight;
        el.style.setProperty("--max-height", height + "px");
      } else {
        return;
      }
    },
  },
};
</script>

<style scoped>
.active-height {
  max-height: var(--max-height);
}
</style>
