<template>
  <div class="mx-auto relative ml-2.5">
    <Navbar class="px-10" />
    <div class="px-12 py-4 mx-auto relative">
      <div class="text-black text-3xl font-bold ml-1">Onboarding Summary</div>
      <div class="text-black ml-1">
        This is a summary of all the experiences in the selected kit and the
        users you want them delivered to. Review and finalise to deliver the
        onboarding kit to the users.
      </div>
      <div>
        <div v-if="obkFlowSummary">
          <div
            class="my-3 bg-nColorFillGray border border-nColorStrokeGray rounded-xl text-black flex flex-col"
          >
            <div class="mx-4 mt-4 pb-2 border-b border-gray-300">
              <span class="text-2xl font-semibold">
                Kit Name -
                <span class="font-bold">
                  {{ obkFlowSummary.kit_summary.details.name }}
                </span>
              </span>
            </div>
            <div class="flex flex-row">
              <div class="w-1/3 m-4">
                <p class="font-semibold mb-2 text-xl">
                  Candidate Experiences
                </p>
                <div class="border rounded-lg bg-white shadow">
                  <div class="w-full bg-red-100 rounded-t-lg">
                    <lottie-animation
                      path="lotties/pre-onboarding.json"
                      :loop="true"
                      :autoPlay="true"
                      :speed="1"
                      :width="256"
                      :height="256"
                    />
                  </div>
                  <Accordion
                    type="experiences"
                    title="Experiences"
                    :content="obkFlowSummary.kit_summary.experiences[0]"
                  />
                  <Accordion
                    type="flows"
                    title="Users"
                    :content="obkFlowSummary.flow_summary[0]"
                  />
                </div>
              </div>
              <div class="w-1/3 m-4">
                <p class="font-semibold mb-2 text-xl">
                  First day at work
                </p>
                <div class="border rounded-lg bg-white">
                  <div class="w-full bg-yellow-100 rounded-t-lg">
                    <lottie-animation
                      path="lotties/date-of-joining.json"
                      :loop="true"
                      :autoPlay="true"
                      :speed="1"
                      :width="256"
                      :height="256"
                    />
                  </div>
                  <Accordion
                    type="experiences"
                    title="Experiences"
                    :content="obkFlowSummary.kit_summary.experiences[1]"
                  />
                  <Accordion
                    type="flows"
                    title="Users"
                    :content="obkFlowSummary.flow_summary[1]"
                  />
                </div>
              </div>
              <div class="w-1/3 m-4">
                <p class="font-semibold mb-2 text-xl">
                  Employee Experiences
                </p>
                <div class="border rounded-lg bg-white shadow">
                  <div class="w-full bg-green-100 rounded-t-lg">
                    <lottie-animation
                      path="lotties/post-onboarding.json"
                      :loop="true"
                      :autoPlay="true"
                      :speed="1"
                      :width="256"
                      :height="256"
                    />
                  </div>
                  <Accordion
                    type="experiences"
                    title="Experiences"
                    :content="obkFlowSummary.kit_summary.experiences[2]"
                  />
                  <Accordion
                    type="flows"
                    title="Users"
                    :content="obkFlowSummary.flow_summary[2]"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="flex place-content-end mt-4">
            <div v-show="errorMsg" class="text-red-500 place-self-end p-3">
              <p class="text-md font-semibold">{{ errorMsg }}</p>
            </div>
            <div>
              <form @submit.prevent="submitFlow">
                <ButtonInput
                  type="submit"
                  buttonText="Finalize"
                  :loading="loading"
                  :buttonClass="
                    'transition shadow duration-300 ease-out tracking-wider bg-nButtonGreen text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none'
                  "
                  :loadingClass="
                    'cursor-not-allowed transition shadow duration-300 ease-out tracking-wider bg-nButtonGreen text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none'
                  "
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonInput from "@/components/Elements/ButtonInput.vue";
import Accordion from "@/components/Elements/Accordion/Accordion.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Navbar from "@/components/Common/Navbar/Plain";

export default {
  name: "AddUsersSummary",
  props: ["id"],
  components: {
    ButtonInput,
    Accordion,
    LottieAnimation,
    Navbar,
  },
  data() {
    return {
      errorMsg: null,
      errObj: null,
      loading: false,
      flowFinalized: false,
      obkFlowSummary: null,
      isFinal: false,
    };
  },
  // beforeRouteLeave(to, from, next) {
  //   if (this.isFinal == false) {
  //     const answer = window.confirm(
  //       "If you exit this page, no user details uploaded will be saved. Do you still want to exit?"
  //     );
  //     if (answer) {
  //       next();
  //     } else {
  //       next(false);
  //     }
  //   }
  // },
  methods: {
    submitFlow() {
      this.loading = true;
      ApiService.post(apiResource.addUsers.finalizeFlow, {
        obk_flow_id: this.id,
      })
        .then(() => {
          this.isFinal = true;
          this.$router.replace({
            name: "AddUsersSuccess",
          });
        })
        .catch((error) => {
          if (typeof error.response.data.msg == "object") {
            this.errObj = error.response.data.msg;
          } else {
            this.errorMsg = error.response.data.msg;
          }
          this.errorMsg = error.response.data.msg;
          this.loading = false;
        });
    },
  },
  mounted() {
    ApiService.get(apiResource.addUsers.getFlowSummary, {
      obk_flow_id: this.id,
    })
      .then(({ data }) => {
        this.obkFlowSummary = data.data;
      })
      .catch(() => {});
  },
};
</script>

<style scoped>
input::file-selector-button {
  font-family: "Rajdhani";
  border: solid 1px #07003b73;
  padding: 0.25em 0.8em;
  border-radius: 10px;
  cursor: pointer;
  transition: 1s;
  margin-right: 16px;
}
.employee-detail p {
  word-spacing: 3px;
}
.h-144 {
  height: 36rem;
}
</style>
